import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import React from "react";
import "../style/card.css";

function Card(props) {
  return (
    <Link to={props.location}>
      <div id="card" className="testcardr">
        <Box>
          <div className="bg-body-tertiary me-md-3 pt-3 px-3 pt-md-4 px-md-4 text-center overflow-hidden ">
            <div className="my-3 py-3">
              <h2 className="display-5" style={{ color: "white" }}>
                {props.name}
              </h2>
              <p classname="lead" style={{ color: "white" }}>
                {props.text}
              </p>
              <Link to={props.location}>
                <button className="btnr">scopri di piu</button>
              </Link>
            </div>
            <div className="bg-body-tertiary shadow-sm mx-auto">
              <img
                className="zoom cardImg"
                alt="maco dj bergamo eventi"
                src={props.img}
              ></img>
            </div>
          </div>
        </Box>
      </div>
    </Link>
  );
}
export default Card;
