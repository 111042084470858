import React from "react";
import Logo from "../images/logo2.png";
import Logo2 from "../images/copyrightLogo.png";
import tiktok from "../images/tiktok.png";
import { Link } from "react-router-dom";
import { SocialIcon } from "react-social-icons";
import GoogleIcon from "../images/googleIcon.png";
import MatrimonioIcon from "../images/logomatri.png";

class Footer extends React.Component {
  render() {
    return (
      <footer
        className="text-center text-lg-start  text-muted "
        style={{
          position: "absolute",
          width: "100%",
          backgroundColor: "black",
        }}
      >
        <section
          className="d-flex justify-content-center"
          style={{ marginTop: "30px" }}
        >
          <div>
            <div className="container text-center">
              <div className="row">
                <div className="col-6" data-aos="fade-up">
                  <Link to="https://www.google.com/maps/place/MACO+DJ+%7C+Live+The+Music+%7C+Musica+&+Intrattenimento/@45.7113002,9.5864068,18z/data=!4m7!3m6!1s0x478153b1cf06d5e3:0x4221e4f9f3856a70!8m2!3d45.7100285!4d9.591208!9m1!1b1">
                    <img
                      alt="maco dj eventi"
                      src={GoogleIcon}
                      style={{ width: "350px", marginRight: "100px" }}
                      className="img-fluid"
                    ></img>
                  </Link>
                </div>
                <div className="col-6" data-aos="fade-up">
                  <Link to="https://www.matrimonio.com/musica-matrimonio/maco-dj-music--e167691">
                    <img
                      src={MatrimonioIcon}
                      alt="logo"
                      style={{
                        width: "350px",
                        marginTop: "5%",
                        backgroundColor: "white",
                        padding: "10px",
                        borderRadius: "10px",
                      }}
                      className="img-fluid"
                    ></img>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="d-flex justify-content-center justify-content-lg-between p-4 "
          style={{
            borderBottomStyle: "solid",
            borderBottomColor: "rgb(0,225,72)",
          }}
        >
          <div className="me-5 d-none d-lg-block">
            <p style={{ color: "white" }}>SEGUICI ANCHE SUI NOSTRI SOCIAL</p>
          </div>
          <div>
            <SocialIcon
              url="https://www.youtube.com/channel/UCTjUf8Va5hkP1-MDWkHe_0w"
              style={{ margin: "5px", width: "35px", height: "35px" }}
              alt="maco dj bergamo eventi"
            />
            <SocialIcon
              url="https://open.spotify.com/user/315rjusjub4uom45bdggniryvpie/playlists"
              style={{ margin: "5px", width: "35px", height: "35px" }}
              alt="maco dj bergamo eventi"
            />
            <SocialIcon
              url="https://www.instagram.com/maco_dj/"
              style={{ margin: "5px", width: "35px", height: "35px" }}
              alt="maco dj bergamo eventi"
            />
            <SocialIcon
              url="https://www.mixcloud.com/macodj/"
              style={{ margin: "5px", width: "35px", height: "35px" }}
              alt="maco dj bergamo eventi"
            />
            <SocialIcon
              url="https://www.facebook.com/macodjeventi/"
              style={{ margin: "5px", width: "35px", height: "35px" }}
              alt="maco dj bergamo eventi"
            />
            <Link to="https://www.tiktok.com/@maco_dj_?_t=8hQr0sL3ddQ&_r=1">
              <img
                src={tiktok}
                style={{ margin: "5px", width: "35px", height: "35px" }}
                alt="maco dj bergamo eventi"
              ></img>
            </Link>
          </div>
        </section>

        <section className="">
          <div className="container text-center text-md-start mt-5">
            <div className="row mt-3">
              <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold mb-4">
                  <Link to="/">
                    <img alt="maco dj bergamo eventi" src={Logo}></img>
                  </Link>
                </h6>
              </div>

              <div
                className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4"
                style={{ color: "white", textAlign: "left" }}
              >
                <h6 className="text-uppercase fw-bold mb-4">
                  MACO DJ | LIVE THE MUSIC
                </h6>
                <h6 className="text-uppercase fw-bold mb-2">
                  DJ PER EVENTI BERGAMO
                </h6>
                <p>A.C.R.New Sound 2.0</p>
                <p>
                  Via Ludovico Ariosto, 4 – 24030 Mozzo (Bg)<br></br>
                  Cell. +39 3342941917 <br></br>
                  macodeejay@gmail.com
                </p>
                <p>
                  P.iva/C.F. 02725570036 |{" "}
                  <Link to="/privacy">Privacy Cookies</Link>
                </p>
              </div>
              <div
                className="opacity-0 transition-opacity duration-500 hover:opacity-100"
                style={{ color: "white" }}
              >
                Passa sopra per vedere il testo!
              </div>
            </div>
          </div>
        </section>

        <div className="text-center p-4">
          © 2023 Copyright
          <img src={Logo2} width="200px" alt="Logo"></img>
        </div>
      </footer>
    );
  }
}

export default Footer;
